export type PositionModal =
  | 'center'
  | 'left'
  | 'top'
  | 'bottom'
  | 'right'
  | 'topleft'
  | 'topright'
  | 'bottomleft'
  | 'bottomright'

export type ConfirmationConfig = {
  group: string
  message: string
  header: string
  icon: string
  position: PositionModal
}

/* Modals */
export const lockClass = 'mcd-modal--open'

/* ModalCenter */
export type ModalCenterDesktopSize = 'medium' | 'large' | 'small'
